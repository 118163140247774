<a routerLink="/preparazioni" class="opzione" id="opzione-preparazioni-architop" *ngFor="let cartella of cartelle" [ngStyle]="{background: (cartella.link) ? 'url('+cartella.link+')' : 'url(./assets/img/immagine-non-disponibile.jpg)', 'background-size' : 'cover', 'background-position' : 'center'}" >
    <div class="spazio-vuoto"></div>
    <div class="titolo-opzione" (click)="salvaTitolo(cartella.cartella)"> 
        <h1>{{ cartella.titolo | titlecase }}</h1>
        <i class="fa fa-chevron-right" style="font-size: 20px;"></i>
    </div>
</a>
<!-- <a routerLink="/preparazioni" class="opzione" id="opzione-preparazioni-architop">
    <div class="spazio-vuoto"></div>
    <div class="titolo-opzione">
        <h1>Preparazioni {{ soluzione | titlecase }}</h1>
        <i class="fa fa-chevron-right" style="font-size: 20px;"></i>
    </div>
</a>

<a routerLink="/applicazioni" class="opzione" id="opzione-applicazioni-architop">
    <div class="spazio-vuoto"></div>
    <div class="titolo-opzione">
        <h1>Applicazioni {{ soluzione | titlecase }}</h1>
        <i class="fa fa-chevron-right" style="font-size: 20px;"></i>
    </div>
</a>

<a routerLink="/protezioni" class="opzione" id="opzione-protezioni-architop">
    <div class="spazio-vuoto"></div>
    <div class="titolo-opzione">
        <h1>Protezioni {{ soluzione | titlecase }}</h1>
        <i class="fa fa-chevron-right" style="font-size: 20px;"></i>
    </div>
</a> -->