import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-prodotto-ordine',
  templateUrl: './prodotto-ordine.component.html',
  styleUrls: ['./prodotto-ordine.component.scss']
})
export class ProdottoOrdineComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
