<a routerLink="/video-tutorial" class="opzione" id="opzione-video-tutorial">
    <div class="spazio-vuoto"></div>
    <div class="titolo-opzione">
        <h1>{{ videoTutorial }}</h1>
        <i class="fa fa-chevron-right" style="font-size: 20px;"></i>
    </div>
</a>

<a routerLink="/documentazione" class="opzione" id="opzione-istruzioni-posa" (click)="posaCertificato('posa')">
    <div class="spazio-vuoto"></div>
    <div class="titolo-opzione">
        <h1>{{ istruzioniDiPosa }}</h1>
        <i class="fa fa-chevron-right" style="font-size: 20px;"></i>
    </div>
</a>

<a routerLink="/tipologie" class="opzione" id="opzione-prodotti">
    <div class="spazio-vuoto"></div>
    <div class="titolo-opzione">
        <h1>{{ iProdotti }}</h1>
        <i class="fa fa-chevron-right" style="font-size: 20px;"></i>
    </div>
</a>

<a routerLink="/categorie-prodotto" class="opzione" id="opzione-attrezzature">
    <div class="spazio-vuoto"></div>
    <div class="titolo-opzione">
        <h1>{{ leAttrezzature }}</h1>
        <i class="fa fa-chevron-right" style="font-size: 20px;"></i>
    </div>
</a>

<a routerLink="/documentazione" class="opzione" id="opzione-documentazione-sistema" (click)="posaCertificato('sistema')">
    <div class="spazio-vuoto"></div>
    <div class="titolo-opzione">
        <h1>{{ cataloghiEcolori }}</h1>
        <i class="fa fa-chevron-right" style="font-size: 20px;"></i>
    </div>
</a>

<a routerLink="/colori-disponibili" class="opzione" id="opzione-colori-disponibili">
    <div class="spazio-vuoto"></div>
    <div class="titolo-opzione">
        <h1>{{ colori }}</h1>
        <i class="fa fa-chevron-right" style="font-size: 20px;"></i>
    </div>
</a>

<a routerLink="/documentazione" class="opzione" id="opzione-certificati" (click)="posaCertificato('certificato')">
    <div class="spazio-vuoto"></div>
    <div class="titolo-opzione">
        <h1>{{ certificati }}</h1>
        <i class="fa fa-chevron-right" style="font-size: 20px;"></i>
    </div>
</a>

<a routerLink="/particolari-esecutivi" class="opzione" id="opzione-particolari-esecutivi">
    <div class="spazio-vuoto"></div>
    <div class="titolo-opzione">
        <h1>{{ particolariEsecutivi }}</h1>
        <i class="fa fa-chevron-right" style="font-size: 20px;"></i>
    </div>
</a>

