<div class="wrapper">
    <div class="prodotto">
        <!-- <p class="codice">Hard-acq</p> -->
        <div class="contenuto-prodotto">
            <img class="immagine-prodotto" src="/assets/img/novita/novita3.jpg">
            <h2 class="titolo-prodotto">Kit attrezzi per decorazione Ideal-wall con cassetta</h2>
            <!-- <p class="descrizione-prodotto">Polvere corazzante al quarzo per pavimentazioni in cls, stampati, stencil ed architop. Disponibile in varie colorazioni. Confezionata in vasi...</p> -->
            <a class="pulsante-vedi-prodotto" RouterLink="/prodotto">vedi prodotto</a>
        </div>
    </div>

    <div class="prodotto">
        <!-- <p class="codice">Hard-acq</p> -->
        <div class="contenuto-prodotto">
            <img class="immagine-prodotto" src="/assets/img/novita/novita1.jpg">
            <h2 class="titolo-prodotto">Kit in busta di attrezzi per decorazione Ideal-wall</h2>
            <!-- <p class="descrizione-prodotto">Polvere corazzante al quarzo per pavimentazioni in cls, stampati, stencil ed architop. Disponibile in varie colorazioni. Confezionata in vasi...</p> -->
            <a class="pulsante-vedi-prodotto" RouterLink="/prodotto">vedi prodotto</a>
        </div>
    </div>
</div>