<div class="esempio">
    <h1>{{ effetto }}</h1>
    <p> {{ testo | titlecase }}
    </p>
</div>

<div class="slider">
  <div class="slides">
    <!-- <div style="background-image: url({{immagine.link}}); background-size: cover;" *ngFor="let immagine of immagini"></div> -->
    <img src="{{ immagine.link }}" width="100%" height="300px" style="margin-right: 10px;" *ngFor="let immagine of immagini">
  </div>
</div>

<!--
<div class="slider">
    <div class="slides">
      <div id="slide-1" style="background-image: url(/assets/img/dettaglio-soluzioni/slide1.jpg); background-size: cover"></div>
      <div id="slide-2" style="background-image: url(/assets/img/dettaglio-soluzioni/slide2.jpg); background-size: cover"></div>
      <div id="slide-3" style="background-image: url(/assets/img/dettaglio-soluzioni/slide3.png); background-size: cover"></div>
      <div id="slide-4" style="background-image: url(/assets/img/dettaglio-soluzioni/slide4.png); background-size: cover"></div>
    </div>
  </div>
-->