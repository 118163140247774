<div class="sup">
    <img class="chiudi" src="/assets/img/close.png" (click)="goBack()">

</div>
<div class="dettaglio-prodotto">
    <div class="sinistra">      
        <p class="codice">{{ codice_materiale }}</p>
        <img class="immagine-prodotto" src="{{ img }}">
    </div>

    <div class="destra">
        <!-- <img class="chiudi" src="/assets/img/left-arrow.png" (click)="goBack()"> -->
        <h1 class="titolo-prodotto">{{ testo }}</h1>
        <p class="descrizione-prodotto" [innerHtml]="descrizione"></p>
        <br>
        <button *ngIf="pdfArray.length > 0" class="btn" (click)="openDialog()">{{ schedaTecnica }}</button>
    </div>
</div>
<!-- <app-modal *ngIf="isShow" #modal></app-modal> -->


<!-- <div class="dettaglio-prodotto">
    <div class="sinistra">
        <p class="codice">Hard-acq</p>
        <img class="immagine-prodotto" src="/assets/img/lista-prodotti/aqua.png">
    </div>

    <div class="destra">
        <h1 class="titolo-prodotto">Corazzante hardener acqua marina "c" in vasi da 25 kg</h1>
        <p class="descrizione-prodotto">Polvere corazzante al quarzo per pavimentazioni in cls, stampati, stencil ed architop. Disponibile in varie colorazioni. Confezionata in vasi...</p>
    </div>
</div> -->

