<div class="navigation" >

  <a routerLink="/soluzioni">
    <img class="logo" src="./assets/img/menu/logo-idealwork.png" style="height: 70px;">
  </a>

  <div class="parte-destra">
    <mat-form-field appearance="fill">
      <mat-label>{{ linguaSelezionata }}</mat-label>
      <mat-select>
        <mat-option *ngFor="let lingua of lingue" (click)="setLingua(lingua.lingua)">
          {{ lingua.lingua }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <button class="ham"></button>
    <nav class="navbar">
      <ul>
        <li><a class="menuLink" routerLink="/soluzioni">Soluzioni</a></li>
        <li><a class="menuLink" routerLink="#">Torna all'app</a></li>
      </ul>
    </nav>
  </div>

</div>

