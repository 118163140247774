<div class="documento" *ngFor="let certificato of certificati">
    <div class="anteprima-documento">
        <!-- <pdf-viewer [src]="certificato.link" [original-size]="false" [page]="1"  [show-all]="false"></pdf-viewer> -->
        <img class="anteprima-documento" src="./assets/img/pdf.png">
        <!-- <img class="anteprima-documento" [src]="certificato.link"> -->
    </div>
    <div class="descrizione-documento">
        <h1 class="titolo-documento" *ngIf="certificato.descrizione !== 'not_found'">{{ certificato.descrizione }}</h1>
        <h2>{{ certificato.titolo }}</h2>
        <button type="submit" class="pulsante-scarica-documento" (click)="goToLink(certificato.link)">
            <img src="./assets/img/documentazione/icona-scarica.png" style="height: 30px;">
            <h3>{{ titolo }}</h3>
        </button>
    </div>
</div>
