import { PageNotFoundComponent } from './../page-not-found/page-not-found.component';
import { Utente } from './../interfaces/utente.interface';
import { TitoloComponent } from './../video-tutorial/titolo/titolo.component';
import { EncrDecrService } from './../encr-decr-service.service';
import { Injectable, Output } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError, from } from 'rxjs';
import { catchError, tap, } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { JwtHelperService } from '@auth0/angular-jwt';
import * as CryptoJS from 'crypto-js';



// IN.p4v1m3nt020

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private http: HttpClient, private encrDecr: EncrDecrService) {
  }

  get staticLingue(): object[] {
    return AuthService.lingue;
  }

  set staticLingue(lingue: object[]) {
    AuthService.lingue = lingue;
  }

  get staticLingua(): string {
    return AuthService.lingua;
  }

  set setStaticLingua(lingua: string) {
    AuthService.lingua = lingua;
  }

  get staticToken(): string {
    return AuthService.token;
  }

  static lingua: string;
  static token: string;
  // baseUrl = 'assets/utenti.json';
  // urlCodici = 'assets/codici.json';
  // loginUrl = '/login';
  static url = 'https://idea.idealwork.it:3000/';
  // static url = 'https://10.7.184.247:3000/';
  // static url = './';
  static secretKey = 'poiuyghj56789yghh';
  static lingue: object[];
  currentUser: Utente;

  public isAuthenticated(): boolean {
    if (sessionStorage.getItem('token')) {
      return false;
    } else {
      return false;
    }
  }
  // tslint:disable-next-line: typedef
  private handleError(error: Response) {
    if (error.statusText === 'Unknow error') {
    return Observable.throw(new PageNotFoundComponent());
    } else {
      throwError(new PageNotFoundComponent());
    }
  }

  getSoluzioni(): Observable<any[]> {
    const key = '123456$#@$^@1ERF';
    const token = this.encrDecr.get(key, sessionStorage.getItem('token'));
    const header = new HttpHeaders()
      .set('Access-Token', token);
    return this.http.get<any[]>(AuthService.url + 'soluzioni/' + (this.staticLingua ? this.staticLingua
      : sessionStorage.getItem('lingua')), { headers: header })
      .pipe(
        tap(resData => {
          // console.log(resData);
        })
      );
  }

  getSoluzioniDettaglio(soluzione: string): Observable<any[]> {
    const key = '123456$#@$^@1ERF';
    const token = this.encrDecr.get(key, sessionStorage.getItem('token'));
    const header = new HttpHeaders()
      .set('Access-Token', token);
    return this.http.get<any[]>(AuthService.url + 'soluzioni/' + (this.staticLingua ? this.staticLingua
      : sessionStorage.getItem('lingua')) + '/' + soluzione, { headers: header })
      .pipe(
        tap(resData => {
          // console.log(resData);
        })
      );
  }

  getSoluzioneColore(): Observable<any[]> {
    const key = '123456$#@$^@1ERF';
    const token = this.encrDecr.get(key, sessionStorage.getItem('token'));
    const header = new HttpHeaders()
      .set('Access-Token', token);
    return this.http.get<any[]>(AuthService.url + 'soluzioni/' + sessionStorage.getItem('lingua') + '/' +
      sessionStorage.getItem('soluzione') + '/' + 'colori'
      , { headers: header })
      .pipe(
        tap(resData => {
          // console.log(resData);
        })
      );
  }

  getSoluzioneCartelle(): Observable<any[]> {
    const key = '123456$#@$^@1ERF';
    const token = this.encrDecr.get(key, sessionStorage.getItem('token'));
    const header = new HttpHeaders()
      .set('Access-Token', token);
    return this.http.get<any[]>(AuthService.url + 'soluzioni/' + sessionStorage.getItem('lingua') + '/' +
      sessionStorage.getItem('soluzione') + '/' + 'cartelle'
      , { headers: header })
      .pipe(
        tap(resData => {
          // console.log(resData);
        })
      );
  }

  getSoluzioneDocumenti(): Observable<any[]> {
    const key = '123456$#@$^@1ERF';
    const token = this.encrDecr.get(key, sessionStorage.getItem('token'));
    const header = new HttpHeaders()
      .set('Access-Token', token);
    return this.http.get<any[]>(AuthService.url + 'soluzioni/' + sessionStorage.getItem('lingua') + '/' +
      sessionStorage.getItem('soluzione') + '/' + 'documenti'
      , { headers: header })
      .pipe(
        tap(resData => {
          // console.log(resData);
        })
      );
  }

  scaricaPdf(link: string): Observable<any> {
    const header = new HttpHeaders()
      .set('Accept', 'application/pdf');
    return this.http.get(link, { headers: header, observe: 'response', responseType: 'blob' }).pipe(tap(resData => {
      // console.log(resData);
    }));
  }

  getLingue(): Observable<any[]> {
    return this.http.get<any[]>(AuthService.url + 'lingue').pipe(
      tap(resData => {
        // console.log(resData);
      }), catchError(this.handleError)
    );
  }

  getSoluzioneSottoCartelle(): Observable<any[]> {
    const key = '123456$#@$^@1ERF';
    const token = this.encrDecr.get(key, sessionStorage.getItem('token'));
    const header = new HttpHeaders()
      .set('Access-Token', token);
    return this.http.get<any[]>(AuthService.url + 'soluzioni/' + sessionStorage.getItem('lingua') + '/' +
      sessionStorage.getItem('soluzione') + '/' + 'cartelle'
      + '/' + sessionStorage.getItem('cartella'), { headers: header })
      .pipe(
        tap(resData => {
         // console.log(resData);
        })
      );
  }

  richiestaAccesso(credentials: { [x: string]: string; }): Observable<any> {
    const header = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded');
    const urlR = AuthService.url + 'request';
    const body = new HttpParams()
      .set('nome', credentials['nome'].trim())
      .set('cognome', credentials['cognome'].trim())
      .set('email', credentials['email'].trim())
      .set('codice', credentials['codice-azienda'])
      .set('note', credentials['note'] ? credentials['note'] : '');
    return this.http.post(urlR, body, { headers: header, observe: 'response', withCredentials: true })
      .pipe(map(response => {
        return response['body'];
      })
      );
  }

  reimpostaPassword(email: string, hash: string, credentials: { [x: string]: string; }): Observable<string> {
    const header = new HttpHeaders()
      .set('Content-type', 'application/x-www-form-urlencoded');
    const urlP = AuthService.url + 'reset';
    const body = new HttpParams()
      .set('email', email)
      .set('hash', hash)
      .set('password', credentials['password']);
    return this.http.post(urlP, body, { headers: header, observe: 'response', withCredentials: true })
      .pipe(map(response => {
        return response.body['result'];
      })
      );
  }

  logIn(credentials: { [x: string]: string; }): Observable<any> {
    localStorage.clear();
    sessionStorage.clear();
    const http = new XMLHttpRequest();
    const promise = new Promise(function (resolve, reject) {
      http.onload = function (): void {
        resolve(this.responseText);
      };
      http.onerror = function (): void {
        reject(this.status);
      };
      // const params = 'username=' + credentials['username'] + '&passowrd=' + credentials['password'];
      // const params = JSON.stringify({ username : credentials['username'], password : credentials['password'] });
      const body = new URLSearchParams();
      body.set('username', credentials['username']);
      body.set('password', credentials['password']);
      const urlL = AuthService.url + 'login';
      http.open('POST', urlL, true);
      http.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
      http.onreadystatechange = () => {
        if (http.readyState === 4 && http.status === 200) {
          const res = JSON.parse(http.response);
          if (http.responseText.length > 30) {
            const token = res.token;
            // console.log(res.download.titolo);
            localStorage.setItem('titoloApp', res.download.titolo);
            localStorage.setItem('descrizioneApp', res.download.descrizione);
            AuthService.lingua = res.utente.lingua;
            AuthService.token = token;
            sessionStorage.setItem('lingua', res.utente.lingua);
            AuthService.lingue = res.lingue;
            AuthService.lingue.forEach((x) => {
              // localStorage.removeItem('linguaArray');
              // console.log(x['lingua']);
            });
            // console.log(AuthService.lingue);
            // console.log(res.lingue);
            // console.log(AuthService.lingue);
          }
        }
      };
      body.forEach(x => {
        // console.log(x);
      });
      http.send(body);
    });
    return from(promise);
  }

  logInApp(username: string, password: string): Observable<any> {
    sessionStorage.clear();
    localStorage.clear();
    localStorage.removeItem('username');
    localStorage.removeItem('password');
    const http = new XMLHttpRequest();
    const promise = new Promise(function (resolve, reject) {
      http.onload = function (): void {
        resolve(this.responseText);
      };
      http.onerror = function (): void {
        reject(this.status);
      };
      // const params = 'username=' + credentials['username'] + '&passowrd=' + credentials['password'];
      // const params = JSON.stringify({ username : credentials['username'], password : credentials['password'] });
      const body = new URLSearchParams();
      password = AuthService.getCryptPassword(password);
      body.set('username', username);
      body.set('password', password);
      console.log(username, password);
      const urlL = AuthService.url + 'login';
      http.open('POST', urlL, true);
      http.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
      http.onreadystatechange = () => {
        if (http.readyState === 4 && http.status === 200) {
          const res = JSON.parse(http.response);
          console.log(res);
          if (http.responseText.length > 30) {
            const token = res.token;
            localStorage.setItem('titoloApp', res.download.titolo);
            localStorage.setItem('descrizioneApp', res.download.descrizione);
            AuthService.lingua = res.utente.lingua;
            AuthService.token = token;
            sessionStorage.setItem('lingua', res.utente.lingua);
            /* AuthService.lingue.forEach((x) => {
              if (x['lingua'] !== res.utente.lingua) {
                if (!localStorage.getItem('linguaArray')) {
                  localStorage.setItem('linguaArray', x['lingua']);
                } else {
                  localStorage.setItem('linguaArray', localStorage.getItem('linguaArray') + ' ' + x['lingua']);
                }
              }
            }); */
          }
        }
      };
      body.forEach(x => {
        // console.log(x);
      });
      http.send(body);
    });
    return from(promise);
  }

  getParamentri(): Observable<any[]> {
    const key = '123456$#@$^@1ERF';
    const token = this.encrDecr.get(key, sessionStorage.getItem('token'));
    const header = new HttpHeaders()
      .set('Access-Token', token);
    console.log(sessionStorage.getItem('lingua'));
    return this.http.get<any[]>(AuthService.url + 'parametri/' + sessionStorage.getItem('lingua')
      , { headers: header })
      .pipe(
        tap(resData => {
          console.log(resData);
        })
      );
  }

  static getCryptPassword(pss: string): string {
    var password = '';
    var passArray = pss.split('_');
    for (var i = 0; i < passArray.length; i++) {
      if (passArray[i] == '1') {
        password += 'a';
      } else if (passArray[i] == '11') {
        password += 'b';
      } else if (passArray[i] == 'x') {
        password += 'c';
      } else if (passArray[i] == '2b') {
        password += 'd';
      } else if (passArray[i] == 'c') {
        password += 'e';
      } else if (passArray[i] == 'k') {
        password += 'f';
      } else if (passArray[i] == 'pl') {
        password += 'g';
      } else if (passArray[i] == 'o3') {
        password += 'h';
      } else if (passArray[i] == '4') {
        password += 'i';
      } else if (passArray[i] == 'w3') {
        password += 'l';
      } else if (passArray[i] == '11o') {
        password += 'm';
      } else if (passArray[i] == 'p7x') {
        password += 'n';
      } else if (passArray[i] == '-') {
        password += 'o';
      } else if (passArray[i] == '113') {
        password += 'p';
      } else if (passArray[i] == 'opx') {
        password += 'q';
      } else if (passArray[i] == 'x3') {
        password += 'r';
      } else if (passArray[i] == '9') {
        password += 's';
      } else if (passArray[i] == 'o87') {
        password += 't';
      } else if (passArray[i] == '34') {
        password += 'u';
      } else if (passArray[i] == 'z') {
        password += 'v';
      } else if (passArray[i] == 'wq') {
        password += 'z';
      } else if (passArray[i] == '90') {
        password += 'w';
      } else if (passArray[i] == '000') {
        password += 'y';
      } else if (passArray[i] == 'x67') {
        password += 'k';
      } else if (passArray[i] == 'opx0') {
        password += 'j';
      } else if (passArray[i] == 'opx1') {
        password += 'x';
      } else if (passArray[i] == 'a') {
        password += '1';
      } else if (passArray[i] == 'dd') {
        password += '2';
      } else if (passArray[i] == 'ee') {
        password += '3';
      } else if (passArray[i] == 'i') {
        password += '4';
      } else if (passArray[i] == 'ii') {
        password += '5';
      } else if (passArray[i] == 'fff') {
        password += '6';
      } else if (passArray[i] == 'xdr') {
        password += '7';
      } else if (passArray[i] == 'pollo') {
        password += '8';
      } else if (passArray[i] == 's') {
        password += '9';
      } else if (passArray[i] == 'ss') {
        password += '0';
      } else if (passArray[i] == '012') {
        password += ' ';
      } else {
        password += passArray[i];
      }
    }
    return password;
  }

}
