<div class="scarica-app">
    <div class="scarica-app-destra">
        <h1>{{ titolo }}</h1>
        <h2>{{ descrizione }}</h2>
        <div class="scarica-app-links">
            <a href="https://play.google.com/store/apps/details?id=it.jumpit.idealwork" target="_blank"><img src="../assets/img/dettaglio-soluzioni/google-play.png"></a>
            <a href="https://apps.apple.com/it/app/ideal-work-24-7/id1513391641" target="_blank"><img src="../assets/img/dettaglio-soluzioni/app-store.png"></a>
        </div>
    </div>
    <img src="../assets/img/dettaglio-soluzioni/mockup-app.png" class="mockup-app">
</div>