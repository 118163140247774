<form #f="ngForm">
    <div class="form-group">

        <div id="password-container">
            <input placeholder="Password" type="password" required minlength="3" maxlength="50" ngModel name="password" #password="ngModel" id="password" class="form-control">
            <i class="fa fa-eye" id="togglePassword"></i>
        </div>

        <div class="alert alert-danger" *ngIf="password.touched && !password.valid">
            <div *ngIf="password.errors.required">La password è obbligatoria</div>
            <div *ngIf="password.errors.minlength">La lunghezza deve essere almeno {{ password.errors.minlength.requiredLength }} caratteri</div>
        </div>

        <div id="password-container">
            <input placeholder="Conferma Password" type="password" required minlength="3" maxlength="50" ngModel name="newPassword" #newPassword="ngModel" id="newPassword" class="form-control">
            <i class="fa fa-eye" id="togglePassword"></i>
        </div>

        <div class="alert alert-danger" *ngIf="newPassword.touched && !newPassword.valid">
            <div *ngIf="newPassword.errors.required">La password è obbligatoria</div>
            <div *ngIf="newPassword.errors.minlength">La lunghezza deve essere almeno {{ password.errors.minlength.requiredLength }} caratteri</div>
        </div>
        <div class="alert alert-danger" *ngIf="newPassword.value != password.value">
            <div>
                Le passwords non coincidono
            </div>
        </div>

        <br/>
        <button class="pulsante-login" (click)="signIn(f.value)">Invia</button>
    </div>
</form>
<br/>
