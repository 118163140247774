import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/core/auth.service';

@Component({
  selector: 'app-opzione',
  templateUrl: './opzione.component.html',
  styleUrls: ['./opzione.component.scss']
})
export class OpzioneComponent implements OnInit {

  constructor(private dataService: AuthService, private routes: Router) {

  }

  dettaglioSoluzione: object[];
  parametri: any = [];
  videoTutorial = '';
  istruzioniDiPosa = '';
  iProdotti = '';
  leAttrezzature = '';
  cataloghiEcolori = '';
  colori = '';
  certificati = '';
  particolariEsecutivi = '';

  ngOnInit(): void {
    this.parametri = JSON.parse(localStorage.getItem('parametri'));
    //console.log(this.parametri);
    this.videoTutorial = this.parametri.find((res) => res.nome === 'Label Video Tutorial').val;
    this.istruzioniDiPosa = this.parametri.find((res) => res.nome === 'Label Istruzioni Di Posa').val;
    this.iProdotti = this.parametri.find((res) => res.nome === 'Label Prodotti').val;
    this.leAttrezzature = this.parametri.find((res) => res.nome === 'Label Attrezzature').val;
    this.cataloghiEcolori = this.parametri.find((res) => res.nome === 'Label Cataloghi e Cartelle Colore').val;
    this.colori = this.parametri.find((res) => res.nome === 'Label Colori Disponibili').val;
    this.certificati = this.parametri.find((res) => res.nome === 'Label Certificati').val;
    this.particolariEsecutivi = this.parametri.find((res) => res.nome === 'Label Particolari Esecutivi').val;
    //console.log(this.parametri.find((res) => res.nome === 'Label Attrezzature').val);
    this.dataService.getSoluzioniDettaglio(sessionStorage.getItem('soluzione'))
    .subscribe((response: object[]) => {
      if (response['message'] === undefined) {
        this.dettaglioSoluzione = response;
      } else {
        // alert('Session Expired');
        // this.routes.navigate(['login']);
      }
    } );
  }

  posaCertificato(nome: string): void {
    sessionStorage.setItem('PosaOCertificato', nome);
  }

}
