<div class="wrapper">
    <div class="prodotto" *ngFor = "let materiale of materiali">
        <p class="codice" [ngStyle]="{'font-size': (materiale.codice_materiale.length > 18) ? '1.2em' : '1.5em'}">{{ materiale.codice_materiale }}</p>
        <div class="contenuto-prodotto">
        <p class="titolo-prodotto">{{ materiale.testo }}</p>
            <img class="immagine-prodotto" src="{{ materiale.img }}">
            <div class="pulsante-vedi-prodotto" routerLink="/prodotto" (click) = "selezioneMateriale(materiale.codice_materiale,materiale.descrizione,materiale.img,materiale.testo,materiale.pdf_link)"><img src="/assets/img/lista-prodotti/search.png"></div>
            <br><br>
            <h2 class="descrizione-prodotto" [innerHtml] = "materiale.descrizione"></h2>
        </div>
    </div>
</div>
