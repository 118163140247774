<form #f="ngForm">
    <div class="form-group">
        <img src="./assets/img/menu/logo-idealwork.png" style="width: 100px; padding-bottom: 50px">
        <input placeholder="E-mail" required minlength="3" maxlength="50" ngModel name="username" #username="ngModel" id="username" type="text" class="form-control">
        <div class="alert alert-danger" *ngIf="username.touched && !username.valid">
            <div *ngIf="username.errors.required">E-mail obbligatoria</div>
            <div *ngIf="username.errors.minlength">La lunghezza deve essere almeno {{ username.errors.minlength.requiredLength }} caratteri</div>
        </div>

        <div id="password-container">
            <input placeholder="Password" type="password" required minlength="3" maxlength="50" ngModel name="password" #password="ngModel" id="password" class="form-control">
            <i class="fa fa-eye" id="togglePassword"></i>
        </div>

        <div class="alert alert-danger" role="alert">
            Username e/o password sbagliati
        </div>


        <div class="alert alert-danger" *ngIf="password.touched && !password.valid">
            <div *ngIf="password.errors.required">La password è obbligatoria</div>
            <div *ngIf="password.errors.minlength">La lunghezza deve essere almeno {{ password.errors.minlength.requiredLength }} caratteri</div>
        </div>
        <br/>
        <button class="pulsante-login" (click)="signIn(f.value)" [disabled] = "clicked">Login</button>

        <div class="richiedi-accesso" (click)="changeHidden()">
            <h1>Richiedi accesso</h1>
            <i class="fa fa-chevron-down" style="font-size: 20px;"></i> 
        </div>

        <div class="richiedi-accesso-down" [hidden]="disabled">
            <input placeholder="Nome" required minlength="3" maxlength="50" ngModel name="nome" #nome="ngModel" id="nome" type="text" class="form-control space-down">
            <input placeholder="Cognome" required minlength="3" maxlength="50" ngModel name="cognome" #cognome="ngModel" id="cognome" type="text" class="form-control space-down">
            <input placeholder="E-mail" required minlength="10" maxlength="50" ngModel name="email" #email="ngModel" id="e-mail" type="text" class="form-control space-down">
            <input placeholder="Codice azienda" required minlength="5" maxlength="50" ngModel name="codice-azienda" #codice="ngModel" id="codice" type="text" class="form-control space-down">
            <div class="alert alert-danger" role="alert">
                Codice non trovato
            </div>
            <input placeholder="Note" required minlength="5" maxlength="50" ngModel name="note" #password="ngModel" id="note" type="text" class="form-control space-down">
            <button class="pulsante-invia-richiesta"  (click)="richiestaAccesso(f.value)">Invia richiesta</button>
        </div>
    </div>
</form>
<br/>
