import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-torna-login',
  templateUrl: './torna-login.component.html',
  styleUrls: ['./torna-login.component.scss']
})
export class TornaLoginComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
