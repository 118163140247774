<p mat-dialog-title>{{ subtitolo }}</p>
<div mat-dialog-content>
    <div class="documento" *ngFor="let pdf of pdfArray" >  
        <!-- <img class="anteprima-documento" src="./assets/img/documentazione/anteprima-documento.png"> -->
        <!-- <div class="anteprima-documento">
            <pdf-viewer [src]="pdf"
                        [original-size]="false"
                        [page]="1"
                        [show-all]="false"
            ></pdf-viewer>
          </div> -->
        <div class="descrizione-documento">
            <h1 class="titolo-documento">{{ titolo }} ( {{ pdf.split('_')[pdf.split('_').length - 1].replace('.pdf', '') }} )</h1>
            <!-- <button type="submit" class="pulsante-scarica-documento" (click)="goToLink(pdf)">
                <img src="./assets/img/documentazione/icona-scarica.png" style="height: 20px;">
            </button> -->
            <button mat-button mat-dialog-close type="submit" class="pulsante-scarica-documento" (click)="goToLink(pdf)">
                <img src="./assets/img/documentazione/icona-scarica.png" style="height: 20px;">
            </button>
            <!-- <a href="{{ pdf }}" target="_blank">{{ pdf }}</a> -->
        </div> 
    </div>
</div>