<div class="prodotto-ordine">
    <select name="numeri" id="numeri" class="dropdown" style="font-size: 20px;">
        <option value="1">1</option>
        <option value="2">2</option>
        <option value="3">3</option>
        <option value="4">4</option>
        <option value="5">5</option>
        <option value="6">6</option>
        <option value="7">7</option>
        <option value="8">8</option>
        <option value="9">9</option>
        <option value="10">10</option>
    </select>
    <h1 class="titolo-prodotto">Corazzante hardener acqua marina "c" in vasi da 25 kg</h1>
    <button type="button" class="cancella">X</button>
</div>